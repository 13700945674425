/* FONTS STYLES
-------------------------------------------------- */
@font-face {
	font-family: 'Helvetica Neue';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/HelveticaNeue.eot');
	src: url('../fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeue.woff') format('woff'),
		url('../fonts/HelveticaNeue.ttf')  format('truetype'),
		url('../fonts/HelveticaNeue.svg#svgFontName') format('svg');
}

@font-face {
	font-family: 'Helvetica Neue Light';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/HelveticaNeue-Light.eot'); /* IE9 Compat Modes */
	src: url('../fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/HelveticaNeue-Light.woff') format('woff'), /* Modern Browsers */
		url('../fonts/HelveticaNeue-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
		url('../fonts/HelveticaNeue-Light.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Helvetica Neue Bold';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/HelveticaNeueLTPro-Bd.eot');
	src: url('../fonts/HelveticaNeueLTPro-Bd.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueLTPro-Bd.ttf')  format('truetype'),
		url('../fonts/HelveticaNeueLTPro-Bd.svg#svgFontName') format('svg');
}


/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.subheader-fluid {
  background-color: #FF0008;
}

.button-enter {
  background-color: #258f00;
  color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}

.button-register {
  background-color: #4461a0;
  color: #ffffff;
  margin-left: 5px;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}

.logo-playdoit {
  margin-left: 5rem;
}

.nav-size {
  font-size: 20px;
}

.img-slide {
  max-width: 100%;
  height: auto;
}


.h-style {
  text-align: left;
  color: #FF0008;
}

.h2-style {
  font-size: 1.79rem;
}

.btn-register {
  background-color: #2a9249;
  font-size: 2rem;
}

.p-decoration {
  font-size: 1.20rem;
  text-decoration: underline;
}

.features {
  background: #E5E6E8;
}

.poup-link {
  background: transparent;
}

.btn-acept {
  background-color: #ffffff;
  color: #2a9249;
  text-transform: uppercase;
  font-size: 12px;
 
}

.btn-aceptM {
  background-color: #ffffff;
  color: #2a9249;
  text-transform: uppercase;
  font-size: 9px;
  margin-top: 15px;
}

.btn-reject {
  background-color: #ffffff;
  color: #FF0008;
  text-transform: uppercase;
  font-size: 12px;
}

.btn-rejectM {
  background-color: #ffffff;
  color: #FF0008;
  text-transform: uppercase;
  font-size: 9px;
  margin-top: 15px;
}

.popup-decoration {
  border: 0px solid rgba(0,0,0,00);
}

.popup-content {
  border-top: 0px solid rgba(0,0,0,00);
  justify-content: center;
}

.img-popup {
  /*position: relative;*/
  margin-bottom: -83px;
}

.img-slideM {
  max-width: 100%;
  max-height: 200px;
}

.carousel-itemM {
  height: 200px;
}

.img-fluidM{
  height: 80px;
}

.h-style-M{
  font-size: 25px;

}

.bodyM {
  margin-top: 10px;
}

.modal-style {
  background-color: transparent;
  border: 0px solid rgba(0,0,0,0);
}

.header__logo {
  display: block;
  width: 220px;
  transition: all .25s;
}